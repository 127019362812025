
export const typeOptions = [{
  value: 1,
  label: '秒杀'
}, {
  value: 2,
  label: '直降'
}, {
  value: 5,
  label: '新人专享'
}, {
  value: 6,
  label: '跨店满减'
}]
export const typeList = {
  2: '直降',
  1: '秒杀',
  4: '限时抢购',
  5: '新人专享',
  6: '跨店满减'
}

export const stateList = [
  {
    value: '-1',
    label: '可报名', // 参加活动
    count: 0
  },
  {
    value: '0',
    label: '待审核', // 取消报名，修改包名信息
    count: 0
  },
  // {
  //   value: "2",
  //   label: "已驳回", // 查看原因， 重新报名
  //   count: 0,
  // },
  // {
  //   value: '3',
  //   label: '已取消', // 参加活动
  //   count: 0
  // },
  {
    value: '1',
    label: '报名成功', // 取消报名， 增加库存
    count: 0
  }
]

/*
  逛农博
*/
export const SHOP_NOW_STATE = [
  { label: '全部', value: null },
  { label: '未发布', value: 0 },
  { label: '已发布', value: 1 }
]
export const SHOP_RECOMMEND_STATE = [
  { label: '全部', value: -1 },
  { label: '未推荐', value: 0 },
  { label: '已推荐', value: 1 }
]
export const DYNAMIC_ICON = [
  { label: '好物', value: 0 },
  { label: '上新', value: 1 }
]

export const discountsType = {
  1: '满减',
  2: '折扣'
}

export const discountsConditionType = {
  1: '金额',
  2: '件数'
}

/* 拼团 */
export const SHOP_GROUP_STATE = [
  { label: '全部', value: '' },
  { label: '未开始', value: 0 },
  { label: '拼团中', value: 1 },
  { label: '已结束', value: 2 }
]
/* 添加拼团 */
export const GROUP_MAN_NUM = [
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 }
]
