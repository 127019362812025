var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "adjustDetail wd-content" },
    [
      _vm._m(0),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "wd-zoom",
          attrs: { "label-width": "120px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "活动商品", required: "" } },
            [
              !_vm.goodsId
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "", size: "mini" },
                      on: {
                        click: () => {
                          _vm.showGoods = true
                        },
                      },
                    },
                    [_vm._v(" 选择商品 ")]
                  )
                : _vm._e(),
              _vm.goodsId
                ? _c("div", { staticClass: "good-card" }, [
                    _c("div", { staticClass: "good-card-left" }, [
                      _c("div", { staticClass: "good-card-left-img" }, [
                        _c("img", { attrs: { src: _vm.imageUrls, alt: "" } }),
                        _c("div", [_vm._v(_vm._s(_vm.goodsName))]),
                      ]),
                      _c("div", { staticClass: "good-card-left-price" }, [
                        _c("div", [_vm._v("￥" + _vm._s(_vm.price))]),
                        _c("div", [
                          _vm._v("当前库存：" + _vm._s(_vm.store_count)),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", plain: "" },
                            on: {
                              click: () => {
                                _vm.showGoods = true
                              },
                            },
                          },
                          [_vm._v(" 更换 ")]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "active-set" }, [_vm._v(" 活动设置 ")]),
          _c(
            "el-form-item",
            { attrs: { label: "拼团类型" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.groupType,
                    callback: function ($$v) {
                      _vm.groupType = $$v
                    },
                    expression: "groupType",
                  },
                },
                [_vm._v(" 常规拼团 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动时间", required: "" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetimerange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  size: "mini",
                  "value-format": "timestamp",
                },
                on: { change: _vm.changeTime },
                model: {
                  value: _vm.activeTime,
                  callback: function ($$v) {
                    _vm.activeTime = $$v
                  },
                  expression: "activeTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.groupType === 1 ? "成团人数" : "成团件数",
                required: "",
              },
            },
            [
              _vm.groupType === 1
                ? _c(
                    "el-select",
                    {
                      staticStyle: { width: "240px" },
                      attrs: {
                        filterable: "",
                        placeholder: "请选择",
                        size: "mini",
                      },
                      model: {
                        value: _vm.groupCount,
                        callback: function ($$v) {
                          _vm.groupCount = $$v
                        },
                        expression: "groupCount",
                      },
                    },
                    _vm._l(_vm.group_countList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  )
                : _c("el-input-number", {
                    attrs: { min: 1 },
                    model: {
                      value: _vm.groupCount,
                      callback: function ($$v) {
                        _vm.groupCount = $$v
                      },
                      expression: "groupCount",
                    },
                  }),
              _vm._v(" " + _vm._s(_vm.groupType === 1 ? "人" : "件") + " "),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "成团时限", required: "" } }, [
            _vm._v(" 3小时 "),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "商品拼团金额", required: "" } },
            [
              _c(
                "div",
                { staticClass: "money-box" },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.radio,
                        callback: function ($$v) {
                          _vm.radio = $$v
                        },
                        expression: "radio",
                      },
                    },
                    [_vm._v(" 单独填写 ")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "2" },
                      model: {
                        value: _vm.radio,
                        callback: function ($$v) {
                          _vm.radio = $$v
                        },
                        expression: "radio",
                      },
                    },
                    [_vm._v(" 批量填写 ")]
                  ),
                  _vm.radio == "2"
                    ? _c("div", { staticClass: "money-box-piliang" }, [
                        _c(
                          "div",
                          [
                            _vm._v(" 拼团金额（元）： "),
                            _c("el-input", {
                              staticStyle: { width: "240px" },
                              attrs: { type: "input" },
                              model: {
                                value: _vm.groupPrice,
                                callback: function ($$v) {
                                  _vm.groupPrice = $$v
                                },
                                expression: "groupPrice",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.radio == "1"
                    ? _c(
                        "div",
                        _vm._l(_vm.goodsSpec, function (item) {
                          return _c(
                            "div",
                            { key: item.id, staticClass: "monry-alone" },
                            [
                              _c("div", { staticClass: "sku-name" }, [
                                _c("span", [_vm._v(_vm._s(item.skuName))]),
                                _c("span", [
                                  _vm._v("库存：" + _vm._s(item.skuStock)),
                                ]),
                                _c("span", [
                                  _vm._v("售价：" + _vm._s(item.skuPrice)),
                                ]),
                              ]),
                              _c(
                                "div",
                                [
                                  _vm._v(" 拼团金额（元）： "),
                                  _c("el-input", {
                                    staticStyle: { width: "240px" },
                                    attrs: { type: "input", size: "mini" },
                                    model: {
                                      value: item.groupPrice,
                                      callback: function ($$v) {
                                        _vm.$set(item, "groupPrice", $$v)
                                      },
                                      expression: "item.groupPrice",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "p",
        { staticClass: "btns" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.submit },
            },
            [_vm._v(" 确认 ")]
          ),
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.cancel } },
            [_vm._v(" 返回 ")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择商品",
            "close-on-click-modal": false,
            visible: _vm.showGoods,
            width: "50%",
            "before-close": () => (_vm.showGoods = false),
          },
          on: {
            "update:visible": function ($event) {
              _vm.showGoods = $event
            },
          },
        },
        [
          _c(
            "search-panel",
            {
              attrs: { flex: "", "show-btn": "" },
              on: { getList: _vm.getList },
            },
            [
              _c(
                "div",
                [
                  _c("p", [_vm._v("商品名称")]),
                  _c("el-input", {
                    staticStyle: { width: "240px" },
                    attrs: {
                      size: "mini",
                      placeholder: "请输入商品名称",
                      clearable: "",
                    },
                    model: {
                      value: _vm.goodsName,
                      callback: function ($$v) {
                        _vm.goodsName = $$v
                      },
                      expression: "goodsName",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm.tableData.length > 0
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    border: "",
                    "element-loading-text": "拼命加载中",
                    "header-row-style": { background: "#F0F2F8" },
                    "header-cell-style": {
                      "font-weight": "bold",
                      background: "#F0F2F8",
                      color: "#333",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "图片", width: "150" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("img", {
                                staticStyle: { width: "100px" },
                                attrs: {
                                  src: scope.row.imageUrls.split(",")[0],
                                  alt: "",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3590374533
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "goodsName", label: "商品名称" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "market_price", label: "原价" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.minMarketPrice) +
                                  " - " +
                                  _vm._s(scope.row.maxMarketPrice) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2698265763
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "stockQuantity", label: "库存" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "operate", label: "操作", fixed: "right" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.stockQuantity > 0 &&
                              scope.row.isGroup == 0
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeGoods(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v(" 选择商品 ")]
                                  )
                                : _vm._e(),
                              scope.row.isGroup == 1
                                ? _c("div", [_vm._v(" 已创建拼团 ")])
                                : _vm._e(),
                              scope.row.stockQuantity == 0
                                ? _c("div", [_vm._v(" 暂无库存 ")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3146903652
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("p", [_vm._v("添加拼团商品")]),
      _c("div", [
        _vm._v(
          "重要说明：商品开始拼团后，该sku将变为锁定状态，无法删除对应的sku，如需删除，请等待活动结束或终止"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }