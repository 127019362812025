<template>
  <div class="adjustDetail wd-content">
    <div class="header">
      <p>添加拼团商品</p>
      <div>重要说明：商品开始拼团后，该sku将变为锁定状态，无法删除对应的sku，如需删除，请等待活动结束或终止</div>
    </div>
    <el-form
      ref="ruleForm"
      label-width="120px"
      class="wd-zoom"
    >
      <el-form-item
        label="活动商品"
        required
      >
        <el-button
          v-if="!goodsId"
          type="primary"
          plain
          size="mini"
          @click="() => {
            showGoods= true
          }"
        >
          选择商品
        </el-button>
        <div
          v-if="goodsId"
          class="good-card"
        >
          <div class="good-card-left">
            <div class="good-card-left-img">
              <img
                :src="imageUrls"
                alt=""
              >
              <div>{{ goodsName }}</div>
            </div>
            <div class="good-card-left-price">
              <div>￥{{ price }}</div>
              <div>当前库存：{{ store_count }}</div>
            </div>
          </div>
          <div>
            <el-button
              type="primary"
              plain
              @click="() => {
                showGoods= true
              }"
            >
              更换
            </el-button>
          </div>
        </div>
      </el-form-item>
      <div class="active-set">
        活动设置
      </div>
      <el-form-item label="拼团类型">
        <el-radio
          v-model="groupType"
          :label="1"
        >
          常规拼团
        </el-radio>
        <!-- <el-radio
          v-model="groupType"
          :label="2"
        >
          凑单拼团（拼团订单以发起人地址为主）
        </el-radio> -->
      </el-form-item>
      <el-form-item
        label="活动时间"
        required
      >
        <el-date-picker
          v-model="activeTime"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="mini"
          value-format="timestamp"
          @change="changeTime"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        :label="groupType === 1 ? '成团人数' : '成团件数'"
        required
      >
        <el-select
          v-if="groupType === 1"
          v-model="groupCount"
          filterable
          placeholder="请选择"
          size="mini"
          style="width:240px;"
        >
          <el-option
            v-for="item in group_countList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-input-number
          v-else
          v-model="groupCount"
          :min="1"
        ></el-input-number>
        {{ groupType === 1 ? '人' : '件' }}
      </el-form-item>
      <el-form-item
        label="成团时限"
        required
      >
        3小时
      </el-form-item>
      <el-form-item
        label="商品拼团金额"
        required
      >
        <div class="money-box">
          <el-radio
            v-model="radio"
            label="1"
          >
            单独填写
          </el-radio>
          <el-radio
            v-model="radio"
            label="2"
          >
            批量填写
          </el-radio>
          <div
            v-if="radio=='2'"
            class="money-box-piliang"
          >
            <div>
              拼团金额（元）：
              <el-input
                v-model="groupPrice"
                type="input"
                style="width:240px;"
              />
            </div>
          </div>
          <div v-if="radio=='1'">
            <div
              v-for="(item) in goodsSpec"
              :key="item.id"
              class="monry-alone"
            >
              <div class="sku-name">
                <span>{{ item.skuName }}</span>
                <span>库存：{{ item.skuStock }}</span>
                <span>售价：{{ item.skuPrice }}</span>
              </div>
              <div>
                拼团金额（元）：
                <el-input
                  v-model="item.groupPrice"
                  type="input"
                  size="mini"
                  style="width:240px;"
                />
              </div>
            </div>
          </div>
        </div>
      </el-form-item>
    </el-form>

    <p class="btns">
      <el-button
        type="primary"
        size="small"
        @click="submit"
      >
        确认
      </el-button>
      <el-button
        size="small"
        @click="cancel"
      >
        返回
      </el-button>
    </p>

    <!--搜索商品-->
    <el-dialog
      title="选择商品"
      :close-on-click-modal="false"
      :visible.sync="showGoods"
      width="50%"
      :before-close="() => (showGoods = false)"
    >
      <search-panel
        flex
        show-btn
        @getList="getList"
      >
        <div>
          <p>商品名称</p>
          <el-input
            v-model="goodsName"
            size="mini"
            style="width: 240px"
            placeholder="请输入商品名称"
            clearable
          />
        </div>
      </search-panel>

      <el-table
        v-if="tableData.length>0"
        v-loading="loading"
        :data="tableData"
        border
        element-loading-text="拼命加载中"
        style="width: 100%"
        :header-row-style="{background: '#F0F2F8'}"
        :header-cell-style="{
          'font-weight': 'bold',
          background: '#F0F2F8',
          color: '#333'
        }"
      >
        <el-table-column
          label="图片"
          width="150"
        >
          <template slot-scope="scope">
            <img
              style="width: 100px"
              :src="scope.row.imageUrls.split(',')[0]"
              alt=""
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="goodsName"
          label="商品名称"
        />
        <el-table-column
          prop="market_price"
          label="原价"
        >
          <template slot-scope="scope">
            {{ scope.row.minMarketPrice }} - {{ scope.row.maxMarketPrice }}
          </template>
        </el-table-column>
        <el-table-column
          prop="stockQuantity"
          label="库存"
        />
        <el-table-column
          prop="operate"
          label="操作"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.stockQuantity>0 && scope.row.isGroup==0"
              type="text"
              @click="changeGoods(scope.row)"
            >
              选择商品
            </el-button>
            <div
              v-if="scope.row.isGroup==1"
            >
              已创建拼团
            </div>
            <div
              v-if="scope.row.stockQuantity==0"
            >
              暂无库存
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
/**
 * 逛农博新增/编辑
 */
// import DetailTitle from '@/components/common/DetailTitle'
import { GROUP_MAN_NUM } from '../../config'
import dayjs from 'dayjs'

export default {
  name: 'AdjustDetail',
  components: {
    // DetailTitle,
  },
  data() {
    return {
      showGoods: false,
      activeTime: '',
      groupType: 1, // 拼团类型 1普通团 2凑单团
      group_countList: GROUP_MAN_NUM, // 动态标签
      groupCount: '2', // 起拼人数
      groupHour: '24', // 时限
      beginTime: '',
      endTime: '',
      goodsName: '', // 商品名称
      goodsId: '', // 商品id
      imageUrls: '', // 商品图片
      goodsSpec: [],
      store_count: '', // 库存
      price: '', // 价格
      // goods_origin_price: '',//原价
      // money: '',//商品拼团金额
      // goods_sku: [],//sku
      // groupPrice:'',//批量填写sku
      // spec: [],//传的值
      groupPrice: '', // 填写的拼团金额
      radio: '1', // 单选
      tableData: [], // 商品列表
      searchTxt: '', // 搜索内容
      loading: false
    }
  },

  methods: {
    // 查找商品
    getList() {
      if (!this.goodsName) {
        this.$message({
          message: '商品名称不能为空',
          type: 'warning'
        })
        return false
      }
      const _this = this
      _this.loading = true
      _this.$axios
        .get(_this.$api.groupBook.book_goods_search, {
          params: {
            goods_name: _this.goodsName,
            goods_id: 0
          }
        })
        .then((res) => {
          const r = res || {}
          if (r.code === 0) {
            _this.tableData = r.data
          } else {
            _this.tableData = []
          }
          _this.loading = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    // 选择商品
    changeGoods(row) {
      this.goodsId = row.goodsId
      this.goodsName = row.goodsName
      this.imageUrls = row.imageUrls
      this.price = `${row.minMarketPrice} - ${row.maxMarketPrice}`
      this.store_count = row.stockQuantity
      let newSdk = []
      row.goodsSpecList.forEach((item, i) => { // marketPrice:商品规格原价, sellPrice: 商品规格现价, stockQuantity: 商品规格库存,
        newSdk[i] = { id: item.id, skuId: item.id, skuKey: item.specKey, skuName: item.specValue, skuPrice: item.sellPrice, skuStock: item.stockQuantity, groupPrice: '' }
      })
      this.goodsSpec = newSdk
      this.showGoods = false
    },

    changeTime(time) {
      if (time) {
        this.beginTime = time[0]
        this.endTime = time[1]
      } else {
        this.beginTime = ''
        this.endTime = ''
      }
    },
    // 提交
    async submit() {
      this.$confirm('确认发布拼团吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        const {
          goodsId,
          goodsName,
          beginTime,
          endTime,
          groupCount,
          groupHour,
          groupPrice,
          goodsSpec,
          groupType
        } = this
        let dataSku = []

        if (this.goodsId === '') {
          this.$message({ message: '请选择商品', type: 'warning' })
          return false
        }
        if (beginTime === '' || endTime === '') {
          this.$message({ message: '请选择时间', type: 'warning' })
          return false
        }
        let warning = ''
        if (this.radio === '1') { // 单独填写
          goodsSpec.forEach((item, i) => {
            if (!item.groupPrice) {
              warning = '请填写完整拼团金额'
              // this.$message({ message: '请填写完整拼团金额', type: 'warning' })
              // return false
            }
            if (item.groupPrice.split('.')[1] && item.groupPrice.split('.')[1].length > 2) {
              warning = '拼团金额小数后最多两位'
              // this.$message({ message: '拼团金额小数后最多两位', type: 'warning' })
              // return false
            }
            if (+item.groupPrice > +item.skuPrice) {
              warning = '拼团金额必须小于等于原价'
              // this.$message({ message: '拼团金额必须小于原价', type: 'warning' })
              // return false
            }
          })
          // dataSku=JSON.stringify(spec);
          dataSku = goodsSpec
        } else if (this.radio === '2') {
          let big = false
          goodsSpec.forEach((item, i) => {
            // console.log(item)
            if (+groupPrice > +item.skuPrice) {
              big = true
            }
          })
          if (!groupPrice) {
            this.$message({ message: '请填写批量拼团金额', type: 'warning' })
            return false
          }
          if (groupPrice.split('.')[1] && groupPrice.split('.')[1].length > 2) {
            this.$message({ message: '拼团金额小数后最多两位', type: 'warning' })
            return false
          }
          if (big) {
            this.$message({ message: '拼团金额必须小于原价', type: 'warning' })
            return false
          }
          let newsku = []
          goodsSpec.forEach((item, i) => {
            newsku[i] = {
              ...item,
              groupPrice: groupPrice }
          })
          dataSku = newsku
        }

        if (warning) {
          this.$message({ message: warning, type: 'warning' })
          return false
        }

        console.log(dataSku)

        this.$axios.post(this.$api.groupBook.book_add, {
          groupType: groupType,
          groupCount: groupCount,
          groupHour: groupHour,
          goodsId: goodsId,
          goodsName: goodsName,
          beginTime: beginTime,
          endTime: endTime,
          goodsSpec: dataSku,
          setType: this.radio === '1' ? 0 : 1
        }).then(res => {
          let r = res || {}
          if (r.code === 0) {
            this.$router.push('/nb/marketingTools/group')
            this.$message({ message: '提交成功', type: 'success' })
          }
        }).catch(error => {
          console.log(error)
        })
      }).catch(() => {})
    },

    cancel() {
      this.$confirm('填写未保存, 是否确定取消?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.$router.push('/nb/marketingTools/group')
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  p {
    font-size: 20px;
    line-height: 22px;
    font-weight: bold;
    margin-right: 40px;
  }
}
.active-set {
  margin-bottom: 10px;
  font-size: 14px;
}
.good-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f2f2f2;
  width: 500px;
  padding: 10px;
}
.good-card-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 390px;
  .good-card-left-img {
    display: flex;
    align-items: center;
    img {
      width: 100px;
      margin-right: 10px;
    }
    div {
      width: 150px;
      line-height: 20px;
    }
  }
  .good-card-left-price {
    line-height: 20px;
    text-align: right;
  }
}
.money-box {
  background: #f2f2f2;
  width: 400px;
  padding: 10px;
  p {
    font-weight: bold;
  }
  .money-box-piliang {
    padding-bottom: 10px;
  }
  .monry-alone {
  }
  .sku-name {
    span {
      margin-right: 30px;
    }
  }
}
.btns {
  padding: 0 120px;
  margin-top: 30px;
}

</style>
